import React, { useState, useEffect, useRef, SetStateAction, useCallback } from 'react';
import { Link, useOutletContext } from 'react-router-dom';
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';

import { Blog } from '@app/types/blog.type';
import { useLazyFeedQuery } from '@app/store/api/common.api';

import { Header } from '@app/widgets/header';

import { CommonOutletContext } from '@app/pages/common.outlet';

import CorporateModal from '../../modals/corporateModal';

import Main3_slider1 from './images/main3_slider1';
import Main3_slider2 from './images/main3_slider2';
import Main3_slider3 from './images/main3_slider3';
import Main3_slider4 from './images/main3_slider4';
import Main3_slider5 from './images/main3_slider5';

// eslint-disable-next-line react-hooks/rules-of-hooks
Swiper.use([Navigation, Pagination]);

const Main = () => {
  const [loadBlackCover, setLoadBlackCover] = useState<boolean>(false);
  const [main1WhiteBg, setMain1WhiteBg] = useState<boolean>(false);
  const [showCorporateModal, setShowCorporateModal] = useState<boolean>(false);
  const [playVideo, setPlayVideo] = useState(false);
  const [casesAnimClass, setCasesAnimClass] = useState<string>('');
  const [blogPosts, setBlogPosts] = useState<Array<Blog>>([]);
  const [slider3Current, setSlider3Current] = useState<number>(0);
  const [slider3Fadeout, setSlider3Fadeout] = useState<number | null>(null);
  const main1VideoRef = useRef<HTMLDivElement | null>(null);
  const main3SliderRef = useRef<HTMLDivElement>(null);
  const main9Item1Ref = useRef<HTMLDivElement>(null);
  const slider3Interval = useRef<number | null>(null);
  const main10Ref = useRef<HTMLDivElement | null>(null);
  const [feedQuery] = useLazyFeedQuery();

  const { commonContext } = useOutletContext<CommonOutletContext>();

  const slider3Autostart = () => {
    if (slider3Interval?.current) {
      slider3Interval.current = window.setInterval(() => {
        setSlider3Current((e) => {
          let next = e + 1;
          if (next > 4) next = 0;
          return next;
        });
      }, 5000);
    }
  };

  const slider3Move = (next: SetStateAction<number>, isClick: boolean) => {
    if (isClick && slider3Interval?.current) window.clearInterval(slider3Interval.current);
    setSlider3Fadeout(slider3Current);
    setSlider3Current(next);
    setTimeout(() => {
      setSlider3Fadeout(null);
    }, 400);
  };

  useEffect(() => {
    if (slider3Interval.current) {
      return window.clearInterval(slider3Interval?.current);
    }
  }, []);

  const main3SliderItems = [
    {
      title: (
        <>
          Seamless <span className="ff-emoji">&#128206;</span> <strong>PowerPoint</strong>{' '}
          integration
        </>
      ),
      text: (
        <>
          We&nbsp;know how frustrating it&nbsp;can be&nbsp;to&nbsp;import a&nbsp;freshly-made
          presentation into PowerPoint, only to&nbsp;realize that some visual elements appear broken
          or&nbsp;misplaced. Wonderslide is&nbsp;built to&nbsp;play well with PowerPoint,
          so&nbsp;you won&rsquo;t have to&nbsp;waste any extra time fixing icons or&nbsp;images
        </>
      ),
      btn: 'Integrate',
      img: <Main3_slider1 slider3Autostart={slider3Autostart} />,
    },
    {
      title: (
        <>
          Built for <span className="ff-emoji">&#128640;</span> <strong>speed</strong>
        </>
      ),
      text: (
        <>
          Making your presentations shine can be&nbsp;a&nbsp;time-consuming process. Wonderslide was
          created to&nbsp;revolutionize this experience, with a&nbsp;commitment to&nbsp;exceptional
          speed ingrained in&nbsp;our core. Designing show-stopping slides has never been this fast,
          or&nbsp;this easy'
        </>
      ),
      btn: 'Speed up',
      img: <Main3_slider2 isActive={slider3Current === 1} />,
    },
    {
      title: (
        <>
          You’re in <span className="ff-emoji">&#128377;</span> <strong>control</strong>
        </>
      ),
      text: (
        <>
          While many other AI-powered presentation solutions generate your content for you,
          we&nbsp;know that&rsquo;s not viable for most professional scenarios. With Wonderslide,
          you&rsquo;re in&nbsp;charge of&nbsp;the content&nbsp;&mdash; our only job is&nbsp;making
          it&nbsp;look the part'
        </>
      ),
      btn: 'Take control',
      img: <Main3_slider3 isActive={slider3Current === 2} />,
    },
    {
      title: (
        <>
          Visual <span className="ff-emoji">&#128081;</span> <strong>wonderland</strong>
        </>
      ),
      text: (
        <>
          Our AI&nbsp;model is&nbsp;trained on&nbsp;thousands of&nbsp;professionally designed
          templates that were created in&nbsp;house by&nbsp;leading visual artists. And it&rsquo;s
          constantly improving&nbsp;&mdash; our team is&nbsp;working tirelessly to&nbsp;make
          Wonderslide the best slide designer that it&nbsp;can&nbsp;be'
        </>
      ),
      btn: 'Become a wizard',
      img: <Main3_slider4 isActive={slider3Current === 3} />,
    },
    {
      title: (
        <>
          Beauty in <span className="ff-emoji">&#128142;</span> <strong>simplicity</strong>
        </>
      ),
      text: (
        <>
          While Wonderslide is&nbsp;incredibly complex under the hood, our goal was to&nbsp;make the
          user experience as&nbsp;simple as&nbsp;possible. No&nbsp;distractions, only the most
          essential elements that you need to&nbsp;deliver captivating presentations'
        </>
      ),
      btn: 'Get started',
      img: <Main3_slider5 isActive={slider3Current === 4} />,
    },
  ];

  let casesAnimated = false;
  const casesObserver = useCallback(
    () =>
      new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting && !casesAnimated) {
              casesAnimated = true;
              setCasesAnimClass('a1');
              setTimeout(() => {
                setCasesAnimClass('a1 a2');
                setTimeout(() => {
                  setCasesAnimClass('a1 a2 a3');
                  setTimeout(() => {
                    setCasesAnimClass('a1 a2 a3 a4');
                    setTimeout(() => {
                      setCasesAnimClass('a1 a2 a3 a4 a5');
                      setTimeout(() => {
                        setCasesAnimClass('a1 a2 a3 a4 a5 a6');
                        setTimeout(() => {
                          setCasesAnimClass('a1 a2 a3 a4 a5 a6 a7');
                          setTimeout(() => {
                            setCasesAnimClass('a1 a2 a3 a4 a5 a6 a7 a8');
                          }, 600);
                        }, 600);
                      }, 600);
                    }, 600);
                  }, 400);
                }, 400);
              }, 400);
            }
          });
        },
        { threshold: 0.1 },
      ),
    [],
  );

  useEffect(() => {
    if (main9Item1Ref?.current) casesObserver().observe(main9Item1Ref.current);

    const point =
      (main1VideoRef?.current?.offsetTop ?? 0) + (main1VideoRef?.current?.clientHeight ?? 0);
    const scrollHandler = () => {
      if (window.scrollY > point) {
        setMain1WhiteBg(true);
        setLoadBlackCover(true);
      } else setMain1WhiteBg(false);
    };
    document.addEventListener('scroll', scrollHandler);
    return () => document.removeEventListener('scroll', scrollHandler);
  }, [casesObserver, feedQuery]);

  useEffect(() => {
    let observer: IntersectionObserver | null = null;
    let retryCount = 0;
    const maxRetries = 3;

    const fetchFeed = () => {
      feedQuery(null)
        .unwrap()
        .then((resultOfFeed) => {
          if (resultOfFeed?.status && resultOfFeed?.result) {
            setBlogPosts(resultOfFeed.result);
            if (observer) {
              observer.disconnect();
            }
          }
        })
        .catch(() => {
          if (retryCount < maxRetries) {
            retryCount++;
            fetchFeed();
          } else {
            if (observer) {
              observer.disconnect();
            }
          }
        });
    };

    if (main10Ref.current) {
      observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              if (!commonContext?.boxed) fetchFeed();
            }
          });
        },
        {
          root: null,
          rootMargin: '0px 0px 400px 0px',
          threshold: 0,
        },
      );

      observer.observe(main10Ref.current);
    }

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, [feedQuery]);

  useEffect(() => {
    const main4Swiper = new Swiper('.main4_slider_inner', {
      loop: true,
      loopAdditionalSlides: 10,
      spaceBetween: 65,
      slidesPerView: 1,
      centeredSlides: true,
      navigation: {
        nextEl: '.main4 .main_slider_btn.right',
        prevEl: '.main4 .main_slider_btn.left',
      },
    });
    main4Swiper.slideTo(1);

    const main7Swiper = new Swiper('.main7_slider_inner', {
      loop: true,
      loopAdditionalSlides: 10,
      spaceBetween: 0,
      slidesPerView: window.innerWidth > 645 ? 3 : 1,
      centeredSlides: true,
      pagination: {
        el: '.main7_slider_nav',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="main7_slider_nav_item ' + className + '"></span>';
        },
      },
      navigation: {
        nextEl: '.main7_slider .main_slider_btn.right',
        prevEl: '.main7_slider .main_slider_btn.left',
      },
    });
    main7Swiper.slideTo(4);
  }, []);

  return (
    <div className={'main'}>
      <link
        rel="preload"
        as="image"
        imageSrcSet="/static/i/main1.webp, /static/i/main1@2x.webp 2x"
      />
      <link
        rel="preload"
        as="image"
        imageSrcSet="/static/i/video_cover_w.webp, /static/i/video_cover_w@2x.webp 2x"
      />
      <div className="bg-[#000000]">
        <Header isTransparent />
      </div>
      <section className={'main1' + (main1WhiteBg ? ' white' : '')}>
        <div className="main1_inner">
          <h1 className="main1_title ff-raleway">
            Say hello to&nbsp;your personal slide designer. Powered by&nbsp;AI
          </h1>
          <div className="main1_text">
            Our neural network makes presentations beautiful, easy, and fast. Incredibly fast
          </div>
          <Link className="main_btn main_btn-l main1_btn ff-raleway" to="/create/">
            Get started
          </Link>
          <div className="main1_video" ref={main1VideoRef}>
            {playVideo && (
              <iframe
                src="https://www.youtube.com/embed/YG17tnCU4Sw?si=P-QovoDsKDOgWZyQ&rel=0&autoplay=1"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerPolicy="strict-origin-when-cross-origin"
                allowFullScreen
                loading="lazy"
              ></iframe>
            )}
            {!playVideo && (
              <>
                <img
                  className={'main1_video_white' + (main1WhiteBg ? ' faded' : '')}
                  srcSet="/static/i/video_cover_w.webp, /static/i/video_cover_w@2x.webp 2x"
                  onClick={() => {
                    setPlayVideo(true);
                  }}
                  alt=""
                  loading="lazy"
                  // fetchPriority="high"
                />
                <img
                  className={'main1_video_black' + (main1WhiteBg ? '' : ' faded')}
                  srcSet={
                    loadBlackCover
                      ? '/static/i/video_cover_b.webp, /static/i/video_cover_b@2x.webp 2x'
                      : ''
                  }
                  onClick={() => {
                    setPlayVideo(true);
                  }}
                  alt="video picture"
                  loading="lazy"
                />
              </>
            )}
          </div>
        </div>
      </section>

      <section className="main2">
        <div className="main2_inner">
          <div className="main2_item">
            <div className="main2_item_content">
              <div className="main2_item_title ff-raleway">Trusted by&nbsp;the&nbsp;community</div>
              <a
                href="https://www.producthunt.com/posts/wonderslide?utm_source=badge-top-post-topic-badge&utm_medium=badge&utm_souce=badge-wonderslide"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="main2_badge"
                  src="/static/i/top-post-topic-badge.svg"
                  alt="Wonderslide - Blazing fast presentation design, powered by AI | Product Hunt"
                  loading="lazy"
                />
              </a>
              <a
                href="https://www.producthunt.com/posts/wonderslide?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-wonderslide"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="main2_badge"
                  src="/static/i/top-post-badge.svg"
                  alt="Wonderslide - Blazing fast presentation design, powered by AI | Product Hunt"
                  loading="lazy"
                />
              </a>
            </div>
          </div>
          <div className="main2_item">
            <div className="main2_item_content">
              <div className="main2_item_title ff-raleway">Featured on</div>
              <img width="240" src="/static/i/ny_post.webp" alt="" loading="lazy" />
              <img width="240" src="/static/i/geekflare.webp" alt="" loading="lazy" />
              <img width="240" src="/static/i/economic_times.webp" alt="" loading="lazy" />
            </div>
          </div>
        </div>
      </section>

      <section className="main3">
        <div className="main3_inner">
          <h2 className="main3_title ff-raleway">
            <strong>
              The{' '}
              <span className="highlight1">
                <span className="text">easiest</span>
              </span>{' '}
              <span className="highlight2">
                <span className="text">AI</span>
              </span>{' '}
              presentation
            </strong>{' '}
            maker you’ll ever use
          </h2>
          <div className="main3_text">
            Design awesome slides in&nbsp;just a&nbsp;few&nbsp;clicks. Customize, brand,
            and&nbsp;export them to&nbsp;.pptx
          </div>

          <div className="main3_slider" ref={main3SliderRef}>
            <div className="main3_slider_inner">
              {main3SliderItems.map((item, i) => (
                <div
                  className={
                    'main3_slide' +
                    (slider3Current === i ? ' active' : '') +
                    (slider3Fadeout === i ? ' fadeout' : '')
                  }
                  key={i}
                >
                  <div className="main3_slide_main">
                    <h3 className="main3_slide_title ff-raleway">{item.title}</h3>
                    <div className="main3_slide_text">{item.text}</div>
                    <Link className="main3_slide_btn main_btn main_btn-l" to="/create/">
                      {item.btn}
                    </Link>
                  </div>
                  {item.img}
                </div>
              ))}
            </div>
            <div className="main3_slider_nav">
              <div
                className="main3_slider_nav_arr prev"
                onClick={() => {
                  return slider3Current > 0 && slider3Move(slider3Current - 1, true);
                }}
              />
              <div
                className="main3_slider_nav_arr next"
                onClick={() => {
                  return slider3Current < 4 && slider3Move(slider3Current + 1, true);
                }}
              />
              {main3SliderItems.map((item, i) => (
                <span
                  className={'main3_slider_nav_item' + (slider3Current === i ? ' active' : '')}
                  key={i}
                  onClick={() => {
                    slider3Move(i, true);
                  }}
                ></span>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="main4">
        <div className="main4_slider">
          <div className="main4_slider_inner">
            <div className="swiper-wrapper">
              {main4SliderItems.map((item, i) => (
                <div className="main4_slide swiper-slide" key={i}>
                  <img className="main4_slide_img" srcSet={item} loading="lazy" alt="" />
                </div>
              ))}
            </div>
          </div>
          <div className="main_slider_btn left">{svgIcons.arrLeft}</div>
          <div className="main_slider_btn right">{svgIcons.arrRight}</div>
        </div>
      </section>

      <section className="main5">
        <div className="main5_inner">
          <div className="main5_main">
            <h2 className="main5_title ff-raleway">
              Presentations: a&nbsp;conduit for the world's{' '}
              <span className="ff-emoji">&#129504;</span> ideas
            </h2>
            <div className="main5_text">
              <p>
                Learn how the humble presentation can be&nbsp;the driving force behind success and
                failure in&nbsp;business
              </p>
              <p>Coming soon…</p>
            </div>
          </div>
          <img
            className="main5_img"
            srcSet="/static/i/main5.webp, /static/i/main5@2x.webp 2x"
            loading="lazy"
            alt=""
          />
        </div>
      </section>

      <section className="main6">
        <h2 className="main6_title ff-raleway">
          So much{' '}
          <span className="highlight">
            <span className="text">more</span>
          </span>{' '}
          than&nbsp;a&nbsp;presentation maker
        </h2>
        <div className="main6_items">
          <div className="main6_item" style={{ backgroundColor: '#6E718F' }}>
            {svgIcons.news} Embedded draft
          </div>
          <div className="main6_item" style={{ backgroundColor: '#FFDA2B', color: '#000' }}>
            {svgIcons.palette} Color Picker
          </div>
          <div className="main6_item" style={{ backgroundColor: '#0099FF' }}>
            {svgIcons.save} Presentation storage
          </div>
          <div className="main6_item" style={{ backgroundColor: '#98D4F2' }}>
            {svgIcons.images} Proprietary image library
          </div>
          <div className="main6_item" style={{ backgroundColor: '#18AB86' }}>
            {svgIcons.upload} Instant export to .pptx and Google Slides
          </div>
          <div className="main6_item" style={{ backgroundColor: '#0E47C0' }}>
            {svgIcons.font} Automatic selection of&nbsp;fonts and&nbsp;colors
          </div>
        </div>
      </section>

      <section className="main7">
        <h2 className="main7_title ff-raleway">
          What <strong>people</strong> are saying
        </h2>
        <div className="main7_slider">
          <div className="main7_slider_inner">
            <div className="swiper-wrapper">
              {main7SliderItems.map((item, i) => (
                <div className="main7_slide swiper-slide" key={i}>
                  <div className="main7_slide_header">
                    <div className="main7_slide_avatar">
                      {item.avatar.type === 'img' && (
                        <img src={item.avatar.src} alt={item.name} loading="lazy" />
                      )}
                      {item.avatar.type === 'letter' && (
                        <div className="ff-raleway" style={{ backgroundColor: item.avatar.color }}>
                          {item.avatar.letter}
                        </div>
                      )}
                    </div>
                    <div className="main7_slide_name ff-raleway">{item.name}</div>
                  </div>
                  <div className="main7_slide_text">{item.text}</div>
                  <div className="main7_slide_rate flex gap-2">
                    {svgIcons.star} {svgIcons.star} {svgIcons.star} {svgIcons.star} {svgIcons.star}
                  </div>
                  <div className="main7_slide_source">{item.source}</div>
                </div>
              ))}
            </div>
          </div>
          <div className="main7_slider_nav"></div>
          <div className="main_slider_btn left">{svgIcons.arrLeft}</div>
          <div className="main_slider_btn right">{svgIcons.arrRight}</div>
        </div>
      </section>

      <section className="main8">
        <div className="main8_inner">
          <img
            className="main8_img"
            srcSet="/static/i/main8.webp, /static/i/main8@2x.webp 2x"
            loading="lazy"
            alt=""
          />
          <div className="main8_main">
            <h2 className="main8_title ff-raleway">
              Keep your
              <br />
              content&nbsp;<span className="ff-emoji">&#128274;</span>&nbsp;safe
            </h2>
            <div className="main8_text">
              Enterprise-grade security to&nbsp;keep your and&nbsp;your customers’ data private
              and&nbsp;secure
            </div>
          </div>
        </div>
      </section>

      <section className={'main9 ' + casesAnimClass}>
        <div className="main9_title ff-raleway">
          <strong>
            AI-designed<span className="ff-emoji">&#128161;</span> presentations
          </strong>{' '}
          for&nbsp;all use cases
        </div>
        <div className="main9_items">
          <div className="main9_item" ref={main9Item1Ref}>
            <div className="main9_item_main">
              <div className="main9_item_title ff-raleway">Sales</div>
              <div className="main9_item_text">
                Enables sales professionals to&nbsp;quickly generate customized presentations for
                potential clients, focusing on&nbsp;targeted solutions and benefits, thus improving
                engagement and conversion rates
              </div>
            </div>
            <div className="main9_item_pics">
              <img
                className="main9_item_img-ex"
                src="/static/i/main4_7.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="main9_item_img-ex"
                src="/static/i/main4_8.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="main9_item_img-ex"
                src="/static/i/main4_10.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="main9_item_img"
                src="/static/i/main_cases1.webp"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
          <div className="main9_item">
            <div className="main9_item_main">
              <div className="main9_item_title ff-raleway">Marketing</div>
              <div className="main9_item_text">
                Assists marketing teams in&nbsp;crafting compelling presentations that effectively
                communicate brand messages, market research findings, and marketing strategies,
                enhancing the impact of&nbsp;campaigns
              </div>
            </div>
            <div className="main9_item_pics">
              <img
                className="main9_item_img-ex"
                src="/static/i/main4_9.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="main9_item_img-ex"
                src="/static/i/main4_1.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="main9_item_img"
                src="/static/i/main_cases2.webp"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
          <div className="main9_item">
            <div className="main9_item_main">
              <div className="main9_item_title ff-raleway">Management</div>
              <div className="main9_item_text">
                Facilitates the swift creation of&nbsp;clear and concise presentations for internal
                meetings, strategic planning, and stakeholder updates, improving decision-making
                processes
              </div>
            </div>
            <div className="main9_item_pics">
              <img
                className="main9_item_img-ex"
                src="/static/i/main4_7.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="main9_item_img-ex"
                src="/static/i/main_cases3_1.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="main9_item_img"
                src="/static/i/main_cases3.webp"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
          <div className="main9_item">
            <div className="main9_item_main">
              <div className="main9_item_title ff-raleway">Education</div>
              <div className="main9_item_text">
                Empowers educators to&nbsp;easily produce educational content that is&nbsp;visually
                engaging and pedagogically structured, enhancing learning outcomes and student
                engagement
              </div>
            </div>
            <div className="main9_item_pics">
              <img
                className="main9_item_img-ex"
                src="/static/i/main4_1.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="main9_item_img-ex"
                src="/static/i/main4_8.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="main9_item_img-ex"
                src="/static/i/main_cases4_1.webp"
                alt=""
                loading="lazy"
              />
              <img
                className="main9_item_img"
                src="/static/i/main_cases4.webp"
                alt=""
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section className="main10" ref={main10Ref}>
        {!commonContext?.boxed && (
          <div className="main10_inner">
            <h2 className="main10_title ff-raleway">
              From our <strong>blog</strong>
            </h2>
            <div className="main10_items">
              {!blogPosts.length &&
                [0, 1, 2].map((i) => (
                  <div className="main10_item empty" key={i}>
                    <div className="main10_item_img" />
                    <div className="main10_item_name"></div>
                    <div className="main10_item_text"></div>
                  </div>
                ))}
              {!!blogPosts.length &&
                blogPosts.map((item: Blog, i) => (
                  <a
                    className="main10_item"
                    href={item.link}
                    target="_blank"
                    key={i}
                    rel="noreferrer"
                  >
                    <img className="main10_item_img" src={item.image} alt="" loading="lazy" />
                    <div className="main10_item_name">{item.title}</div>
                    <div className="main10_item_text">{item.description}</div>
                  </a>
                ))}
            </div>
            <a className="main_btn main_btn-l ff-raleway" href="/blog/">
              Explore our blog
            </a>
          </div>
        )}
      </section>

      <section className="main12">
        <h2 className="main11_title ff-raleway">
          Try it now. It's{' '}
          <strong className="highlight">
            <span className="text">simple</span>
          </strong>
        </h2>
        <Link to="/create/" className="main_btn main_btn-l main12_btn">
          Get started
        </Link>
      </section>
      {showCorporateModal && (
        <CorporateModal
          close={() => {
            setShowCorporateModal(false);
          }}
        />
      )}
    </div>
  );
};

export default Main;

const main4SliderItems = [
  '/static/i/main4_1.webp, /static/i/main4_1@2x.webp 2x',
  '/static/i/main4_2.webp, /static/i/main4_2@2x.webp 2x',
  '/static/i/main4_3.webp, /static/i/main4_3@2x.webp 2x',
  '/static/i/main4_4.webp, /static/i/main4_4@2x.webp 2x',
  '/static/i/main4_5.webp, /static/i/main4_5@2x.webp 2x',
  '/static/i/main4_6.webp, /static/i/main4_6@2x.webp 2x',
  '/static/i/main4_7.webp, /static/i/main4_7@2x.webp 2x',
  '/static/i/main4_8.webp, /static/i/main4_8@2x.webp 2x',
  '/static/i/main4_9.webp, /static/i/main4_9@2x.webp 2x',
  '/static/i/main4_10.webp, /static/i/main4_10@2x.webp 2x',
];

const main7SliderItems = [
  {
    name: 'Tyler',
    avatar: {
      type: 'letter',
      letter: 'T',
      color: '#0E47C0',
    },
    text: (
      <>
        <p>
          Wonderslide is&nbsp;different from most of&nbsp;the other&nbsp;Al slide deck solutions out
          there because it&nbsp;doesn&rsquo;t write your slides for you. Instead, it&nbsp;focuses
          on&nbsp;making your slides look damn good. It&rsquo;s sort of&nbsp;like having your own
          in-house designer.{' '}
        </p>
        <p>
          I&nbsp;appreciate that they are focusing on&nbsp;leveraging&nbsp;Al for the visual
          elements of&nbsp;the presentation rather than the content, because in&nbsp;general
          I&rsquo;ve been underwhelmed by&nbsp;the Als that have tried to&nbsp;write my&nbsp;slides
          for&nbsp;me
        </p>
      </>
    ),
    source: 'On his blog',
    rate: 5,
  },
  {
    name: 'Carlos',
    avatar: {
      type: 'letter',
      letter: 'C',
      color: '#98D4F2',
    },
    text: (
      <>
        <p>
          Wonderslide is&nbsp;a&nbsp;must-have tool for anyone who makes presentations
          on&nbsp;a&nbsp;regular basis. The tool&rsquo;s advanced&nbsp;Al makes the design process
          quick and easy
        </p>
      </>
    ),
    source: 'Quoted from a review',
    rate: 5,
  },
  {
    name: 'John',
    avatar: {
      type: 'letter',
      letter: 'J',
      color: '#000',
    },
    text: (
      <>
        <p>Wonderslide has saved me&nbsp;a&nbsp;lot of&nbsp;time and effort creating slides. </p>
        <p>
          It&rsquo;s amazing how&nbsp;Al can generate attractive covers in&nbsp;a&nbsp;matter
          of&nbsp;seconds!
        </p>
      </>
    ),
    source: 'Quoted from a review',
    rate: 5,
  },
  {
    name: 'Shayla',
    avatar: {
      type: 'img',
      src: '/static/i/main7_avatar1.webp',
    },
    text: (
      <>
        <p>Wonderslide is a&nbsp;huge time-saver!</p>
        <p>
          It&nbsp;takes my vision and outline and turns it into a&nbsp;fully realised and easily
          accessible presentation with bold graphics and eye-catching design.
        </p>
      </>
    ),
    source: 'Received by email',
    rate: 5,
  },
  {
    name: 'Gregory',
    avatar: {
      type: 'img',
      src: '/static/i/main7_avatar2.webp',
    },
    text: (
      <>
        <p>Wonderslide has really eased my pain during presentation preparation.</p>
        <p>
          I no longer spend hours fixing positions of&nbsp;text and&nbsp;figures, picking up
          examples from the Internet and my own experience.
        </p>
        <p>And now Wonderslide gets an upvote, which is&nbsp;truly deserved.</p>
      </>
    ),
    source: 'Received by email',
    rate: 5,
  },
  {
    name: 'Alex',
    avatar: {
      type: 'img',
      src: '/static/i/main7_avatar3.webp',
    },
    text: (
      <>
        <p>
          I work as a marketer. You constantly need to&nbsp;make a lot of presentations for
          partners.{' '}
        </p>
        <p>
          It became much easier for me to work when I&nbsp;started using Wonderslide. Now I have
          time to&nbsp;swim in the pool.
        </p>
      </>
    ),
    source: 'Received by email',
    rate: 5,
  },

  {
    name: 'Rob',
    avatar: {
      type: 'letter',
      letter: 'R',
      color: '#0099FF',
    },
    text: (
      <>
        <p>
          My&nbsp;wife and&nbsp;I run a&nbsp;small business, and our workflow involves lots and lots
          of&nbsp;presentations.{' '}
        </p>
        <p>
          We&nbsp;started using Wonderslide fairly recently but it&nbsp;has already saved&nbsp;us
          countless hours on&nbsp;what used to&nbsp;be&nbsp;an&nbsp;extremely tedious job
        </p>
      </>
    ),
    source: 'Over email',
    rate: 5,
  },
  {
    name: 'Jenny',
    avatar: {
      type: 'letter',
      letter: 'J',
      color: '#18AB86',
    },
    text: (
      <>
        <p>
          I&nbsp;love how simple it&nbsp;is&nbsp;to&nbsp;use, and how fast it&nbsp;allows
          me&nbsp;to&nbsp;try out different design ideas
        </p>
      </>
    ),
    source: 'User interview',
    rate: 5,
  },
  {
    name: 'Dmitrii',
    avatar: {
      type: 'letter',
      letter: 'D',
      color: '#6E718F',
    },
    text: (
      <>
        <p>A revolutionary tool for&nbsp;presentations</p>
      </>
    ),
    source: 'User interview',
    rate: 5,
  },
];

const svgIcons = {
  arrLeft: (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none">
      <path
        d="M14.1783 20.8217L11.8217 23.1783L0.643333 12L11.8217 0.821671L14.1783 3.17834L7.02333 10.3333H23V13.6667H7.02333L14.1783 20.8217Z"
        fill="white"
      />
    </svg>
  ),
  arrRight: (
    <svg width="23" height="24" viewBox="0 0 23 24" fill="none">
      <path
        d="M8.82167 20.8218L11.1783 23.1784L22.3567 12.0001L11.1783 0.821777L8.82167 3.17844L15.9767 10.3334H0V13.6668H15.9767L8.82167 20.8218Z"
        fill="white"
      />
    </svg>
  ),
  news: (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M33.1247 5H6.87467C4.92134 5 3.33301 6.495 3.33301 8.33333V31.6667C3.33301 33.505 4.92134 35 6.87467 35H33.1247C35.078 35 36.6663 33.505 36.6663 31.6667V8.33333C36.6663 6.495 35.078 5 33.1247 5ZM33.1247 31.6667H6.87467C6.77967 31.6667 6.71467 31.64 6.68634 31.64C6.67467 31.64 6.66801 31.6433 6.66634 31.6533L6.64634 8.41C6.65801 8.39333 6.73301 8.33333 6.87467 8.33333H33.1247C33.2563 8.335 33.328 8.38 33.333 8.34667L33.353 31.59C33.3413 31.6067 33.2663 31.6667 33.1247 31.6667Z"
        fill="white"
      />
      <path
        d="M10 11.6666H20V21.6666H10V11.6666ZM21.6667 25H10V28.3333H30V25H23.3333H21.6667ZM23.3333 18.3333H30V21.6666H23.3333V18.3333ZM23.3333 11.6666H30V15H23.3333V11.6666Z"
        fill="white"
      />
    </svg>
  ),
  palette: (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M22.3329 3.49333C19.5848 3.11719 16.7863 3.42732 14.1872 4.39604C11.5882 5.36476 9.26941 6.96194 7.4379 9.045C5.8704 10.8503 4.70737 12.9701 4.02693 15.2621C3.3465 17.5541 3.16441 19.9652 3.4929 22.3333C4.37623 28.8233 9.25623 34.345 15.6346 36.0767C17.0564 36.4658 18.5237 36.6642 19.9979 36.6667L20.2346 36.665C21.0771 36.6541 21.9033 36.4306 22.6364 36.0151C23.3695 35.5996 23.9857 35.0056 24.4279 34.2883C24.8716 33.5749 25.1276 32.7609 25.1722 31.9219C25.2167 31.083 25.0484 30.2464 24.6829 29.49L24.3512 28.7967C24.0961 28.2983 23.9763 27.7416 24.0038 27.1824C24.0313 26.6232 24.2051 26.081 24.5079 25.61C24.9411 24.9063 25.6198 24.3882 26.4128 24.1558C27.2057 23.9234 28.0567 23.9932 28.8012 24.3517L29.4879 24.68C30.1746 25.01 30.9046 25.1783 31.6562 25.1783C32.9717 25.1716 34.2323 24.6505 35.1685 23.7264C36.1048 22.8023 36.6423 21.5486 36.6662 20.2333C36.6869 18.68 36.4888 17.1315 36.0779 15.6333C34.3446 9.25667 28.8229 4.37667 22.3329 3.49333ZM30.9296 21.675L30.2429 21.3467C27.1962 19.8833 23.4596 21.0167 21.6846 23.8367C20.4546 25.7967 20.3296 28.13 21.3446 30.24L21.6762 30.9333C21.8005 31.1852 21.858 31.4648 21.8431 31.7453C21.8283 32.0258 21.7415 32.2977 21.5912 32.535C21.4448 32.7774 21.2385 32.9781 20.9922 33.1179C20.7459 33.2577 20.4678 33.3319 20.1846 33.3333H19.9979C18.8183 33.3301 17.6444 33.1715 16.5062 32.8617C11.4012 31.4767 7.50123 27.0633 6.79623 21.885C6.26123 17.96 7.3829 14.18 9.95123 11.2367C11.4125 9.56038 13.2697 8.27608 15.3538 7.50063C17.438 6.72518 19.683 6.48318 21.8846 6.79667C27.0629 7.50167 31.4762 11.4033 32.8612 16.5067C33.1912 17.7233 33.3496 18.9633 33.3312 20.185C33.3112 21.53 31.9229 22.1567 30.9296 21.675Z"
        fill="black"
      />
      <path
        d="M12.5 26.6666C13.8807 26.6666 15 25.5473 15 24.1666C15 22.7859 13.8807 21.6666 12.5 21.6666C11.1193 21.6666 10 22.7859 10 24.1666C10 25.5473 11.1193 26.6666 12.5 26.6666Z"
        fill="#ED1FFF"
      />
      <path
        d="M12.5 20C13.8807 20 15 18.8807 15 17.5C15 16.1193 13.8807 15 12.5 15C11.1193 15 10 16.1193 10 17.5C10 18.8807 11.1193 20 12.5 20Z"
        fill="#09C4FF"
      />
      <path
        d="M17.5 15C18.8807 15 20 13.8807 20 12.5C20 11.1193 18.8807 10 17.5 10C16.1193 10 15 11.1193 15 12.5C15 13.8807 16.1193 15 17.5 15Z"
        fill="#FF0000"
      />
      <path
        d="M24.167 15C25.5477 15 26.667 13.8807 26.667 12.5C26.667 11.1193 25.5477 10 24.167 10C22.7863 10 21.667 11.1193 21.667 12.5C21.667 13.8807 22.7863 15 24.167 15Z"
        fill="#18AB86"
      />
    </svg>
  ),
  save: (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none">
      <path
        d="M3.33333 30H26.6667C27.5507 30 28.3986 29.6488 29.0237 29.0237C29.6488 28.3986 30 27.5507 30 26.6667V8.33336C30.0013 8.11402 29.9592 7.89658 29.8763 7.69352C29.7933 7.49045 29.6711 7.30576 29.5167 7.15003L22.85 0.483361C22.6943 0.328892 22.5096 0.206683 22.3065 0.123742C22.1035 0.0408015 21.886 -0.0012403 21.6667 2.78571e-05H3.33333C2.44928 2.78571e-05 1.60143 0.351217 0.976311 0.976338C0.351189 1.60146 0 2.44931 0 3.33336V26.6667C0 27.5507 0.351189 28.3986 0.976311 29.0237C1.60143 29.6488 2.44928 30 3.33333 30ZM20 26.6667H10V18.3334H20V26.6667ZM16.6667 6.66669H13.3333V3.33336H16.6667V6.66669ZM3.33333 3.33336H6.66667V10H20V3.33336H20.9833L26.6667 9.01669V26.6667H23.3333V18.3334C23.3333 17.4493 22.9821 16.6015 22.357 15.9763C21.7319 15.3512 20.8841 15 20 15H10C9.11595 15 8.2681 15.3512 7.64298 15.9763C7.01786 16.6015 6.66667 17.4493 6.66667 18.3334V26.6667H3.33333V3.33336Z"
        fill="white"
      />
    </svg>
  ),
  images: (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path
        d="M33.8333 3.33337H13.8333C11.995 3.33337 10.5 4.82837 10.5 6.66671V26.6667C10.5 28.505 11.995 30 13.8333 30H33.8333C35.6717 30 37.1667 28.505 37.1667 26.6667V6.66671C37.1667 4.82837 35.6717 3.33337 33.8333 3.33337ZM13.8333 26.6667V6.66671H33.8333L33.8367 26.6667H13.8333Z"
        fill="white"
      />
      <path
        d="M7.16634 13.3334H3.83301V33.3334C3.83301 35.1717 5.32801 36.6667 7.16634 36.6667H27.1663V33.3334H7.16634V13.3334Z"
        fill="white"
      />
      <path d="M20.5 20L18.8333 18.3334L15.5 23.3334H32.1667L25.5 13.3334L20.5 20Z" fill="white" />
    </svg>
  ),
  upload: (
    <svg width="41" height="40" viewBox="0 0 41 40" fill="none">
      <path
        d="M22.1663 31.6666V25H27.1663L20.4997 16.6666L13.833 25H18.833V31.6666H22.1663Z"
        fill="white"
      />
      <path
        d="M12.1663 31.6667H15.4997V28.3334H12.1663C9.40967 28.3334 7.16634 26.09 7.16634 23.3334C7.16634 20.9934 9.16467 18.74 11.6213 18.3084L12.5897 18.1384L12.9097 17.2084C14.0813 13.79 16.9913 11.6667 20.4997 11.6667C25.0947 11.6667 28.833 15.405 28.833 20V21.6667H30.4997C32.338 21.6667 33.833 23.1617 33.833 25C33.833 26.8384 32.338 28.3334 30.4997 28.3334H25.4997V31.6667H30.4997C34.1763 31.6667 37.1663 28.6767 37.1663 25C37.1638 23.5061 36.6607 22.056 35.7375 20.8814C34.8143 19.7068 33.5241 18.8755 32.073 18.52C31.3447 12.7834 26.433 8.33337 20.4997 8.33337C15.9063 8.33337 11.9163 11.0184 10.0947 15.25C6.51467 16.32 3.83301 19.7 3.83301 23.3334C3.83301 27.9284 7.57134 31.6667 12.1663 31.6667Z"
        fill="white"
      />
    </svg>
  ),
  font: (
    <svg width="40" height="40" viewBox="0 0 40 40" fill="none">
      <path
        d="M8.33301 30H31.6663V35H8.33301V30ZM20.833 6.66663H19.1663C18.498 6.66663 17.893 7.06663 17.6313 7.68163L9.57467 26.6666H13.1963L15.318 21.6666H24.658L26.7713 26.6666H30.3897L22.368 7.68329C22.2403 7.38178 22.0266 7.12457 21.7536 6.94376C21.4806 6.76296 21.1604 6.66657 20.833 6.66663ZM16.733 18.3333L19.998 10.64L23.2497 18.3333H16.733Z"
        fill="white"
      />
    </svg>
  ),
  star: (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none">
      <path
        d="M5.52447 1.08156C5.67415 0.620904 6.32585 0.620902 6.47553 1.08156L7.09607 2.99139C7.29688 3.60943 7.87282 4.02786 8.52265 4.02786H10.5308C11.0151 4.02786 11.2165 4.64767 10.8247 4.93237L9.20006 6.11271C8.67433 6.49468 8.45434 7.17173 8.65516 7.78976L9.2757 9.69959C9.42537 10.1602 8.89813 10.5433 8.50628 10.2586L6.88168 9.07827C6.35595 8.6963 5.64405 8.69631 5.11832 9.07827L3.49372 10.2586C3.10187 10.5433 2.57463 10.1603 2.7243 9.69959L3.34484 7.78976C3.54566 7.17173 3.32567 6.49468 2.79994 6.11271L1.17534 4.93237C0.783482 4.64767 0.984868 4.02786 1.46923 4.02786H3.47735C4.12719 4.02786 4.70312 3.60942 4.90393 2.99139L5.52447 1.08156Z"
        fill="url(#paint0_linear_2387_941)"
        stroke="#F2CF75"
      />
      <defs>
        <linearGradient
          id="paint0_linear_2387_941"
          x1="6"
          y1="-2"
          x2="6"
          y2="14"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FFEC43" />
          <stop offset="1" stopColor="#DDC913" />
        </linearGradient>
      </defs>
    </svg>
  ),
  album: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M14.699 15.3812L13.3337 13.3332L10.667 17.3332H22.667L18.0003 10.6665L14.699 15.3812Z"
        fill="#6E718F"
      />
      <path
        d="M12.671 12.0002C13.7778 12.0002 14.675 11.103 14.675 9.99619C14.675 8.88941 13.7778 7.99219 12.671 7.99219C11.5642 7.99219 10.667 8.88941 10.667 9.99619C10.667 11.103 11.5642 12.0002 12.671 12.0002Z"
        fill="#6E718F"
      />
      <path
        d="M25.3333 2.6665H8C6.392 2.6665 4 3.73184 4 6.6665V25.3332C4 28.2678 6.392 29.3332 8 29.3332H28V26.6665H8.016C7.4 26.6505 6.66667 26.4078 6.66667 25.3332C6.66667 24.2585 7.4 24.0158 8.016 23.9998H28V5.33317C28 3.8625 26.804 2.6665 25.3333 2.6665ZM25.3333 21.3332H6.66667V6.6665C6.66667 5.59184 7.4 5.34917 8 5.33317H25.3333V21.3332Z"
        fill="#6E718F"
      />
    </svg>
  ),
  download: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M25.2639 14.8158C24.6812 10.2265 20.7505 6.6665 16.0052 6.6665C12.3305 6.6665 9.13854 8.8145 7.68121 12.1998C4.81721 13.0558 2.67188 15.7598 2.67188 18.6665C2.67188 22.3425 5.66254 25.3332 9.33854 25.3332H10.6719V22.6665H9.33854C7.13321 22.6665 5.33854 20.8718 5.33854 18.6665C5.33854 16.7945 6.93721 14.9905 8.90254 14.6452L9.67721 14.5092L9.93321 13.7652C10.8705 11.0305 13.1972 9.33317 16.0052 9.33317C19.6812 9.33317 22.6719 12.3238 22.6719 15.9998V17.3332H24.0052C25.4759 17.3332 26.6719 18.5292 26.6719 19.9998C26.6719 21.4705 25.4759 22.6665 24.0052 22.6665H21.3385V25.3332H24.0052C26.9465 25.3332 29.3385 22.9412 29.3385 19.9998C29.337 18.8045 28.9347 17.6443 28.196 16.7045C27.4574 15.7647 26.425 15.0998 25.2639 14.8158Z"
        fill="#6E718F"
      />
      <path
        d="M17.3385 18.6668V13.3335H14.6719V18.6668H10.6719L16.0052 25.3335L21.3385 18.6668H17.3385Z"
        fill="#6E718F"
      />
    </svg>
  ),
  toggleLeft: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M10.667 12C8.49633 12 6.66699 13.8293 6.66699 16C6.66699 18.1707 8.49633 20 10.667 20C12.8377 20 14.667 18.1707 14.667 16C14.667 13.8293 12.8377 12 10.667 12Z"
        fill="#6E718F"
      />
      <path
        d="M21.3337 8H10.667C6.26699 8 2.68166 11.5853 2.66699 16V16.0213C2.67615 18.1383 3.52262 20.1656 5.02154 21.6605C6.52046 23.1554 8.55003 23.9965 10.667 24H21.3337C23.4529 23.9965 25.4845 23.1536 26.9838 21.6558C28.4831 20.158 29.328 18.1273 29.3337 16.008V16C29.3217 11.588 25.735 8 21.3337 8ZM21.3337 21.3333H10.667C9.25546 21.3312 7.90214 20.7705 6.90279 19.7736C5.90343 18.7768 5.33929 17.4249 5.33366 16.0133C5.33899 13.0653 7.73099 10.6667 10.667 10.6667H21.3337C24.2697 10.6667 26.6617 13.0653 26.667 16.008C26.6624 17.4203 26.0988 18.7734 25.0994 19.7713C24.0999 20.7693 22.746 21.3309 21.3337 21.3333ZM26.667 16.0213L27.2577 16.016L28.0003 16.0213H26.667Z"
        fill="#6E718F"
      />
    </svg>
  ),
  category: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M13.3333 4H5.33333C4.97971 4 4.64057 4.14048 4.39052 4.39052C4.14048 4.64057 4 4.97971 4 5.33333V13.3333C4 13.687 4.14048 14.0261 4.39052 14.2761C4.64057 14.5262 4.97971 14.6667 5.33333 14.6667H13.3333C13.687 14.6667 14.0261 14.5262 14.2761 14.2761C14.5262 14.0261 14.6667 13.687 14.6667 13.3333V5.33333C14.6667 4.97971 14.5262 4.64057 14.2761 4.39052C14.0261 4.14048 13.687 4 13.3333 4ZM12 12H6.66667V6.66667H12V12ZM26.6667 4H18.6667C18.313 4 17.9739 4.14048 17.7239 4.39052C17.4738 4.64057 17.3333 4.97971 17.3333 5.33333V13.3333C17.3333 13.687 17.4738 14.0261 17.7239 14.2761C17.9739 14.5262 18.313 14.6667 18.6667 14.6667H26.6667C27.0203 14.6667 27.3594 14.5262 27.6095 14.2761C27.8595 14.0261 28 13.687 28 13.3333V5.33333C28 4.97971 27.8595 4.64057 27.6095 4.39052C27.3594 4.14048 27.0203 4 26.6667 4ZM25.3333 12H20V6.66667H25.3333V12ZM13.3333 17.3333H5.33333C4.97971 17.3333 4.64057 17.4738 4.39052 17.7239C4.14048 17.9739 4 18.313 4 18.6667V26.6667C4 27.0203 4.14048 27.3594 4.39052 27.6095C4.64057 27.8595 4.97971 28 5.33333 28H13.3333C13.687 28 14.0261 27.8595 14.2761 27.6095C14.5262 27.3594 14.6667 27.0203 14.6667 26.6667V18.6667C14.6667 18.313 14.5262 17.9739 14.2761 17.7239C14.0261 17.4738 13.687 17.3333 13.3333 17.3333ZM12 25.3333H6.66667V20H12V25.3333ZM22.6667 17.3333C19.7253 17.3333 17.3333 19.7253 17.3333 22.6667C17.3333 25.608 19.7253 28 22.6667 28C25.608 28 28 25.608 28 22.6667C28 19.7253 25.608 17.3333 22.6667 17.3333ZM22.6667 25.3333C21.196 25.3333 20 24.1373 20 22.6667C20 21.196 21.196 20 22.6667 20C24.1373 20 25.3333 21.196 25.3333 22.6667C25.3333 24.1373 24.1373 25.3333 22.6667 25.3333Z"
        fill="#6E718F"
      />
    </svg>
  ),
  userCircle: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M16.0003 2.6665C12.4781 2.71142 9.11272 4.1306 6.6219 6.62142C4.13109 9.11223 2.71191 12.4776 2.66699 15.9998C2.68346 18.0517 3.17329 20.0721 4.09834 21.9037C5.0234 23.7353 6.35871 25.3287 8.00033 26.5598V26.6665H8.13366C10.3912 28.3965 13.1561 29.334 16.0003 29.334C18.8445 29.334 21.6094 28.3965 23.867 26.6665H24.0003V26.5598C25.6419 25.3287 26.9773 23.7353 27.9023 21.9037C28.8274 20.0721 29.3172 18.0517 29.3337 15.9998C29.2887 12.4776 27.8696 9.11223 25.3787 6.62142C22.8879 4.1306 19.5226 2.71142 16.0003 2.6665ZM10.7603 25.2398C10.955 24.3484 11.4485 23.5504 12.1591 22.978C12.8697 22.4057 13.7546 22.0935 14.667 22.0932H17.3337C18.2461 22.0935 19.131 22.4057 19.8415 22.978C20.5521 23.5504 21.0457 24.3484 21.2403 25.2398C19.6521 26.1742 17.843 26.667 16.0003 26.667C14.1577 26.667 12.3485 26.1742 10.7603 25.2398ZM23.4803 23.5198C22.9732 22.3078 22.1191 21.2726 21.0256 20.5443C19.932 19.816 18.6476 19.4272 17.3337 19.4265H14.667C13.3531 19.4272 12.0687 19.816 10.9751 20.5443C9.8815 21.2726 9.02749 22.3078 8.52033 23.5198C7.52121 22.5372 6.72553 21.3672 6.17874 20.0769C5.63196 18.7866 5.34479 17.4012 5.33366 15.9998C5.36824 13.1816 6.50315 10.4885 8.49609 8.4956C10.489 6.50266 13.1821 5.36775 16.0003 5.33317C18.8186 5.36775 21.5116 6.50266 23.5046 8.4956C25.4975 10.4885 26.6324 13.1816 26.667 15.9998C26.6559 17.4012 26.3687 18.7866 25.8219 20.0769C25.2751 21.3672 24.4794 22.5372 23.4803 23.5198Z"
        fill="#6E718F"
      />
      <path
        d="M16.0003 7.99984C15.2955 7.98342 14.5946 8.11015 13.9401 8.37236C13.2856 8.63457 12.6911 9.02682 12.1925 9.52537C11.694 10.0239 11.3017 10.6184 11.0395 11.2729C10.7773 11.9274 10.6506 12.6283 10.667 13.3332C10.6506 14.038 10.7773 14.7389 11.0395 15.3934C11.3017 16.0479 11.694 16.6424 12.1925 17.141C12.6911 17.6395 13.2856 18.0318 13.9401 18.294C14.5946 18.5562 15.2955 18.6829 16.0003 18.6665C16.7052 18.6829 17.4061 18.5562 18.0606 18.294C18.7151 18.0318 19.3096 17.6395 19.8081 17.141C20.3067 16.6424 20.6989 16.0479 20.9611 15.3934C21.2234 14.7389 21.3501 14.038 21.3337 13.3332C21.3501 12.6283 21.2234 11.9274 20.9611 11.2729C20.6989 10.6184 20.3067 10.0239 19.8081 9.52537C19.3096 9.02682 18.7151 8.63457 18.0606 8.37236C17.4061 8.11015 16.7052 7.98342 16.0003 7.99984ZM16.0003 15.9998C15.6456 16.017 15.2912 15.9598 14.9599 15.8318C14.6286 15.7039 14.3277 15.508 14.0766 15.2569C13.8255 15.0058 13.6296 14.7049 13.5017 14.3736C13.3737 14.0423 13.3165 13.6879 13.3337 13.3332C13.3165 12.9784 13.3737 12.624 13.5017 12.2927C13.6296 11.9614 13.8255 11.6606 14.0766 11.4094C14.3277 11.1583 14.6286 10.9625 14.9599 10.8345C15.2912 10.7066 15.6456 10.6494 16.0003 10.6665C16.3551 10.6494 16.7095 10.7066 17.0408 10.8345C17.3721 10.9625 17.6729 11.1583 17.9241 11.4094C18.1752 11.6606 18.371 11.9614 18.499 12.2927C18.6269 12.624 18.6841 12.9784 18.667 13.3332C18.6841 13.6879 18.6269 14.0423 18.499 14.3736C18.371 14.7049 18.1752 15.0058 17.9241 15.2569C17.6729 15.508 17.3721 15.7039 17.0408 15.8318C16.7095 15.9598 16.3551 16.017 16.0003 15.9998Z"
        fill="#6E718F"
      />
    </svg>
  ),
  chat: (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none">
      <path
        d="M6.66699 24V29.0213L8.68699 27.8093L15.0363 24H21.3337C22.8043 24 24.0003 22.804 24.0003 21.3333V10.6667C24.0003 9.196 22.8043 8 21.3337 8H5.33366C3.86299 8 2.66699 9.196 2.66699 10.6667V21.3333C2.66699 22.804 3.86299 24 5.33366 24H6.66699ZM5.33366 10.6667H21.3337V21.3333H14.2977L9.33366 24.312V21.3333H5.33366V10.6667Z"
        fill="#6E718F"
      />
      <path
        d="M26.6667 2.6665H10.6667C9.196 2.6665 8 3.8625 8 5.33317H24C25.4707 5.33317 26.6667 6.52917 26.6667 7.99984V18.6665C28.1373 18.6665 29.3333 17.4705 29.3333 15.9998V5.33317C29.3333 3.8625 28.1373 2.6665 26.6667 2.6665Z"
        fill="#6E718F"
      />
    </svg>
  ),
};
