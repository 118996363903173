import { MouseEvent } from 'react';
import { NavLink } from 'react-router-dom';
import { useOutletContext } from 'react-router';

import t from '@lib/lng';
import { route_path } from '@app/utils/route_path';
import { useLogOutMutation } from '@app/store/api/user.api';
import { CommonOutletContext } from '@app/pages/common.outlet';
import {
  CogIcon,
  CreditCardIcon,
  LogoutIcon,
  PickColorIcon,
  StarIcon,
  UserIcon,
  UsersIcon,
} from '@app/components/ui/icons';
import { Popover, PopoverContent, PopoverTrigger } from '@app/shared/ui/popover';
import { cvaIcon } from '@app/shared/ui/elements';

import { Button } from '@app/shared/ui/button';

import { cvaMenuItem } from '@app/widgets/header/ui/header-styles';

import { useAppDispatch } from '@app/hooks/use-store';

import { toggleHeaderMenu } from '../model/header.slice';

import {
  cvaMainContent,
  cvaNoProductMessage,
  cvaPlanSubtitle,
  cvaPlanTitle,
  cvaPopoverContent,
  cvaPopoverTrigger,
  cvaProfileEmail,
  cvaProfileInfo,
  cvaProfileRoleTag,
} from './header-menu-styles';

export const HeaderMenu = () => {
  const dispatch = useAppDispatch();

  const { setEmailNotify, setShowPayments, setShowSettings, clearUserCtx } =
    useOutletContext<CommonOutletContext>();

  const handleNavigationClick = () => dispatch(toggleHeaderMenu(false));

  // const navigate = useNavigate();
  const [logoutQuery, { isLoading: isLoadingLogoutMutation }] = useLogOutMutation();

  const logOutHandler = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    logoutQuery().then(() => {
      handleNavigationClick();
      // clearUserCtx();
      // navigate(route_path.main);
      // window.location.reload();
      window.location.href = route_path.main; //Need reload page after logout
    });
  };

  const { user_ctx, context, commonContext } = useOutletContext<CommonOutletContext>();

  const group = context?.group;
  const TAG = context?.TAG;
  const product_balance = context?.product_balance;

  return (
    <Popover>
      <PopoverTrigger className={cvaPopoverTrigger()}>
        <UserIcon className={cvaIcon()} />
        <span>Account</span>
      </PopoverTrigger>

      <PopoverContent align="end" className={cvaPopoverContent()} sideOffset={24}>
        <div className={cvaProfileInfo()}>
          {/* Email */}
          <div className={cvaProfileEmail()}>{user_ctx?.username}</div>

          {/* Profile tags */}
          {Object.keys(TAG ?? {}).length > 0 && (
            <div className={cvaProfileRoleTag()}>
              {!group ||
                !group[0] ||
                (group[0] === t.admin && <StarIcon className={cvaIcon({ size: 'small' })} />)}
              {group && group[0] ? <span>{group[0]}</span> : <span>{t.Owner}</span>}
            </div>
          )}
        </div>

        {/* Content */}
        <div className={cvaMainContent()}>
          {/* Confirm email button */}
          {!user_ctx?.is_confirmed && (
            <Button
              size="text18"
              variant="ghost"
              onClick={() => {
                setEmailNotify(true);
              }}
            >
              Confirm your email
            </Button>
          )}

          {user_ctx?.is_confirmed && (
            <>
              {/* Product expired alert */}
              {!product_balance?.is_active && product_balance?.product && (
                <div className={cvaNoProductMessage()}>
                  {product_balance.product.is_trial
                    ? t.formatString(t.TrialExpiredPlease, <br />)
                    : t.formatString(t.SubscriptionExpiredPlease, <br />)}
                  <Button size="text18" variant="ghost" asChild>
                    <NavLink to={route_path.pricing}>{t.upgradeYourPlan}</NavLink>
                  </Button>
                </div>
              )}

              {/* Product balance alert */}
              {product_balance?.is_active && (
                <div>
                  <div
                    className={cvaPlanTitle({
                      isHighlight: product_balance.product.name.toLowerCase().includes('premium'),
                    })}
                  >
                    {product_balance?.product.name}
                    {product_balance?.count &&
                      `, ${product_balance?.count} presentation${product_balance.count > 1 ? 's' : ''}`}
                  </div>
                  {product_balance.count && (
                    <div className={cvaPlanSubtitle()}>
                      {product_balance.count} download
                      {product_balance.count > 1 ? 's' : ''} available
                    </div>
                  )}
                </div>
              )}

              {/* Payments button */}
              {!commonContext?.boxed && (
                <button
                  type="button"
                  className={cvaMenuItem({ isDotHidden: true })}
                  onClick={() => setShowPayments(true)}
                >
                  <CreditCardIcon className={cvaIcon({ size: 'large' })} />
                  <span>{t.Payments}</span>
                </button>
              )}
            </>
          )}

          {/* Settings button */}
          <button
            type="button"
            className={cvaMenuItem({ isDotHidden: true })}
            onClick={() => setShowSettings(true)}
          >
            <CogIcon className={cvaIcon({ size: 'large' })} />
            <span>Settings</span>
          </button>

          {/* Admin button */}
          {Object.keys(TAG ?? {}).length > 0 && (
            <>
              <a className={cvaMenuItem({ isDotHidden: true })} href={route_path.team}>
                <UsersIcon className={cvaIcon({ size: 'large' })} />
                <span>{t.Users}</span>
              </a>
              <a className={cvaMenuItem({ isDotHidden: true })} href={route_path.teamAttribute}>
                <PickColorIcon className={cvaIcon({ size: 'large' })} />
                <span>{t.corporateStyle}</span>
              </a>
            </>
          )}

          {/* Logout button */}
          <button
            className={cvaMenuItem({ isDotHidden: true })}
            onClick={logOutHandler}
            disabled={isLoadingLogoutMutation}
          >
            <LogoutIcon className={cvaIcon({ size: 'large' })} />
            <span>Log out</span>
          </button>

          {user_ctx?.is_superuser && (
            <Button variant="primary" asChild>
              <a href={route_path.admin}>Admin</a>
            </Button>
          )}
        </div>
      </PopoverContent>
    </Popover>
  );
};
